<template>
  <section class="applicant-comments-item">
    <p>
      <span class="applicant-comments-item__employer-name">
        {{ comment.employerName }}
      </span>
      <span class="applicant-comments-item__created-at">
        - {{ createdAt }}
      </span>
    </p>
    <p class="applicant-comments-item__body">
      {{ comment.body }}
    </p>
    <p class="applicant-comments-item__actions">
      <span
        v-if="!isConfirmDelete"
        data-test="applicant-comment-delete"
        class="applicant-comments-item__action"
        @click="isConfirmDelete = true"
      >
        {{ $t(`${langPath}.deleteLabel`) }}
      </span>
      <span
        v-if="isConfirmDelete"
      >
        {{ $t(`${langPath}.confirmDeleteLabel`) }}
        <span
          data-test="applicant-comment-confirm"
          class="applicant-comments-item__action"
          @click="handleDelete()"
        >
          {{ $t(`${langPath}.deleteConfirmed`) }}
        </span>
        -
        <span
          class="applicant-comments-item__action"
          @click="isConfirmDelete = false"
        >
          {{ $t(`${langPath}.deleteCanceled`) }}
        </span>
      </span>
    </p>
  </section>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import { COMMENTS } from '@/store/namespaces'

const commentsHelper = createNamespacedHelpers(COMMENTS)

export default {
  name: 'ApplicantCommentsItem',
  props: {
    comment: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      langPath: __langpath,
      isConfirmDelete: false
    }
  },
  computed: {
    createdAt () {
      return this.$moment(this.comment.createdAt).calendar()
    }
  },
  methods: {
    ...commentsHelper.mapActions(['deleteComment']),
    handleDelete () {
      this.deleteComment({
        applicantId: this.$route.params.applicantId,
        commentId: this.comment.id
      })
    }
  }
}
</script>

<style lang="scss">
.applicant-comments-item {
  margin-top: 5*$base;

  &__created-at {
    @extend %caption;

    color: $gray60;
  }

  &__employer-name {
    @extend %body-text2;
    @extend %bold;

    color: $gray100;
  }

  &__body {
    color: $gray100;
    margin-top: 2*$base;
    overflow-wrap: break-word;
    white-space: pre-line;

    @extend %body-text2;
  }

  &__actions {
    @extend %body-text2;

    margin-top: 2*$base;
  }

  &__action {
    color: $blue;
    cursor: pointer;
    display: inline-block;
    text-decoration: underline;
  }
}
</style>
