<template>
  <section class="applicant-comments-form">
    <ev-text-area
      v-model="body"
      :errors="errorMessages($v.body)"
      :placeholder="$t(`${langPath}.placeholder`)"
      class="applicant-comments-form__text-area"
      data-test="comment-input"
      name="body"
      @blur="$v.$touch"
      @input="$v.$touch"
    />

    <span class="applicant-comments-form__wrapper">
      <icn-locked height="13" class="applicant-comments-form__wrapper__icon" />

      <span class="applicant-comments-form__wrapper__assistive-text">
        {{ $t(`${langPath}.commentAssistiveText`) }}
      </span>

      <ev-button
        :disabled="$v.$invalid"
        data-test="send-comment-button"
        class="applicant-comments-form__wrapper__button"
        variant="secondary"
        color="blue"
        size="small"
        @click="handleSend"
      >
        {{ $t(`${langPath}.add`) }}
      </ev-button>
    </span>
  </section>
</template>

<script>
import { TheNotificationCardList, IcnLocked } from '@revelotech/everest'

import EvButton from '@revelotech/everestV2/EvButton'
import EvTextArea from '@revelotech/everestV2/EvTextArea'

import { createNamespacedHelpers } from 'vuex'
import { COMMENTS } from '@/store/namespaces'

import { required } from 'vuelidate/lib/validators'

const commentsHelper = createNamespacedHelpers(COMMENTS)

export default {
  name: 'ApplicantCommentsForm',

  components: {
    EvButton,
    EvTextArea,
    IcnLocked
  },

  data () {
    return {
      langPath: __langpath,
      body: ''
    }
  },

  validations: {
    body: { required }
  },

  methods: {
    ...commentsHelper.mapActions(['createComment']),
    handleSend () {
      this.$v.$touch()

      if (this.$v.$error) return

      this.createComment({
        applicantId: this.$route.params.applicantId,
        body: this.body
      }).then(() => {
        TheNotificationCardList.createNotification({
          message: this.$t(`${this.langPath}.commentCreatedSuccess`),
          modifier: 'success'
        })

        this.body = ''
      })
    }
  }
}
</script>

<style lang="scss">
.applicant-comments-form {
  margin-bottom: 8*$base;
  margin-top: 5*$base;

  &__wrapper {
    display: flex;
    margin-top: $base;

    &__button {
      color: $blue;
      margin-left: auto;
    }

    &__icon {
      margin-left: $base;
    }

    &__assistive-text {
      @extend %caption;

      color: $gray60;
      margin-left: $base;
    }
  }
}
</style>
