<template>
  <section v-if="applicant" class="applicant-comments">
    <applicant-comments-form />
    <applicant-comments-item
      v-for="comment of comments"
      :key="comment.id"
      :comment="comment"
    />
  </section>
</template>

<script>
import ApplicantCommentsForm from './components/ApplicantCommentsForm'
import ApplicantCommentsItem from './components/ApplicantCommentsItem'
import { createNamespacedHelpers } from 'vuex'
import { APPLICANTS, COMMENTS } from '@/store/namespaces'

const applicantHelper = createNamespacedHelpers(APPLICANTS)
const commentsHelper = createNamespacedHelpers(COMMENTS)

export default {
  name: 'ApplicantComments',
  components: {
    ApplicantCommentsForm,
    ApplicantCommentsItem
  },
  data () {
    return {
      langPath: __langpath
    }
  },
  computed: {
    ...commentsHelper.mapState(['comments']),
    ...applicantHelper.mapGetters(['applicant'])
  },
  created () {
    this.getComments(this.$route.params.applicantId)
  },
  methods: {
    ...commentsHelper.mapActions(['getComments'])
  }
}
</script>

<style lang="scss">
.applicant-comments {
  margin-top: 6*$base;

  &__title { @extend %subtitle1; }
}
</style>
